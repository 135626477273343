<template lang="">
    <div class="lesson">
        <!-- Mobile -->
        <div class="box-mobile" v-if="!isLoading">
            <h2 class="lesson__title">{{ courseObject.title }}</h2>
            <button @click="handleDrawer(true)">
                <v-icon class="fa-solid fa-list-ul"></v-icon>
            </button>
            <a-drawer width="315" placement="right" :visible="visible" :closable="false" @close="handleDrawer(false)">
                <button class="btn-back" @click="handleDrawer(false)">
                    <v-icon class="fa-solid fa-xmark"></v-icon>
                </button>
                <div class="lesson__menu">
                    <div class="list">
                        <router-link
                            :to="{ path: `/lesson-page/${lessonObject.id}`, query: { course_id: courseObject.id } }"
                            :class="`item ${lessonObject.id === lessonActive.id && 'active'}`"
                        >
                            <span>{{ lessonObject.title }}</span>
                            <v-icon class="fa-solid fa-circle-check"></v-icon>
                        </router-link>
                        <router-link
                            v-for="subLesson in lessonObject.sub_lessons"
                            :key="subLesson.id"
                            :class="`item ${subLesson.id === lessonActive.id && 'active'}`"
                            :to="{
                                path: `/lesson-page/${subLesson.lesson_id}`,
                                query: { course_id: courseObject.id, sub_id: subLesson.id },
                            }"
                        >
                            <span>{{ subLesson.title }}</span>
                            <v-icon class="fa-solid fa-circle-check" v-if="subLesson.is_finished"></v-icon>
                        </router-link>
                    </div>
                </div>
            </a-drawer>
        </div>

        <!-- Desktop -->
        <a-row :gutter="[16, 16]" v-if="!isLoading">
            <a-col :xs="0" :sm="0" :lg="0" :xl="24">
                <h2 class="mb-0">{{ courseObject.title }}</h2>
            </a-col>
            <a-col :xs="0" :sm="0" :lg="0" :xl="6" class="lesson__menu">
                <div class="lesson__menu-content">
                    <h4>{{ $t('lesson_page.title') }}</h4>
                    <div class="list">
                        <router-link
                            :to="{ path: `/lesson-page/${lessonObject.id}`, query: { course_id: courseObject.id } }"
                            :class="`item ${lessonObject.id === lessonActive.id && 'active'}`"
                        >
                            <span>{{ lessonObject.title }}</span>
                            <v-icon class="fa-solid fa-circle-check"></v-icon>
                        </router-link>
                        <router-link
                            v-for="subLesson in lessonObject.sub_lessons"
                            :key="subLesson.id"
                            :class="`item ${subLesson.id === lessonActive.id && 'active'}`"
                            :to="{
                                path: `/lesson-page/${subLesson.lesson_id}`,
                                query: { course_id: courseObject.id, sub_id: subLesson.id },
                            }"
                        >
                            <span>{{ subLesson.title }}</span>
                            <v-icon class="fa-solid fa-circle-check" v-if="subLesson.is_finished"></v-icon>
                        </router-link>
                    </div>
                </div>
            </a-col>
            <a-col :xs="24" :sm="24" :lg="24" :xl="18">
                <div class="lesson__content">
                    <h3 class="lesson_subTitle">{{ lessonActive.title }}</h3>
                    <div class="lesson__text">
                        <MyEditor :data="lessonActive.text" :isReadOnly="true" />
                    </div>
                    <div class="lesson__btns">
                        <button @click="handleAction('PREV')" :disabled="!prevLink">
                            {{ $t('lesson_page.prev') }}
                        </button>
                        <button @click="handleAction('FINISH')">
                            {{ $t('lesson_page.finish') }}
                        </button>
                        <button @click="handleAction('NEXT')" :disabled="!nextLink">
                            {{ $t('lesson_page.next') }}
                        </button>
                    </div>
                </div>
            </a-col>
        </a-row>

        <Loading v-if="isLoading" />
    </div>
</template>

<script>
import MyEditor from '../../CMS/MyEditor';
import Loading from '../../../components/Web/Loading/Loading.vue';
import * as LessonAPI from '../../../api/lessonAPI';
import * as SubLessonAPI from '../../../api/subLessonAPI';
import { Course } from '../../../api';
import { mixinToasts } from '../../../mixins';

export default {
    mixins: [mixinToasts],

    components: {
        Loading,
        MyEditor,
    },

    data() {
        return {
            visible: false,
            isLoading: false,
            courseObject: null,
            lessonObject: null,
            lessonActive: null,
            prevLink: null,
            nextLink: null,
        };
    },

    created() {
        this.isLoading = true;
        this.getData();
    },

    watch: {
        '$route.query': function () {
            this.handleActive();
        },
    },

    methods: {
        async getData() {
            try {
                const courseData = await Course.findOne(this.$route.query.course_id, { fields: ['id', 'title'] });
                const lessonData = await LessonAPI.findOne(this.$route.params.id, {
                    fields: ['$all', { sub_lesson: ['$all'] }],
                    order: [['sub_lessons', 'order', 'desc']],
                    course_id: this.$route.query.course_id,
                });
                this.courseObject = courseData?.results?.object;
                this.lessonObject = lessonData?.results?.object;
                this.handleActive();
                setTimeout(() => {
                    this.isLoading = false;
                }, 500);
            } catch (error) {
                this.isLoading = false;
                this.showToast('error', error?.response?.data?.message);
            }
        },

        async handleActive() {
            const { sub_id } = this.$route.query;
            const { id: courseId } = this.courseObject;
            const { id: lessonId, sub_lessons } = this.lessonObject;
            const path = `/lesson-page/${lessonId}`;

            if (sub_id) {
                const index = sub_lessons?.findIndex((subLesson) => subLesson?.id === sub_id);
                const currentSubLesson = sub_lessons[index];

                // Active lesson
                this.lessonActive = sub_lessons[index];

                // Mark learned
                if (!currentSubLesson.is_finished) {
                    try {
                        await SubLessonAPI.finished({ is_finished: true, sub_lesson_id: sub_id, course_id: courseId });
                        this.lessonObject.sub_lessons[index].is_finished = true;
                    } catch (error) {
                        this.showToast('error', error?.response?.data?.message);
                    }
                }

                // Hanlde link of prev button and next button
                if (index === 0) {
                    this.prevLink = { path: path, query: { course_id: courseId } };
                    this.nextLink = { path: path, query: { course_id: courseId, sub_id: sub_lessons[index + 1]?.id } };
                } else if (index === sub_lessons.length - 1) {
                    this.prevLink = { path: path, query: { course_id: courseId, sub_id: sub_lessons[index - 1]?.id } };
                    this.nextLink = null;
                } else {
                    this.prevLink = { path: path, query: { course_id: courseId, sub_id: sub_lessons[index - 1]?.id } };
                    this.nextLink = { path: path, query: { course_id: courseId, sub_id: sub_lessons[index + 1]?.id } };
                }
            } else {
                // Active lesson
                this.lessonActive = this.lessonObject;

                // Mark learned
                try {
                    await SubLessonAPI.finished({ is_finished: true, lesson_id: lessonId, course_id: courseId });
                } catch (error) {
                    this.showToast('error', error?.response?.data?.message);
                }

                // Hanlde link of prev button and next button
                this.prevLink = null;
                if (sub_lessons.length > 0) {
                    this.nextLink = { path: path, query: { course_id: courseId, sub_id: sub_lessons[0]?.id } };
                } else {
                    this.nextLink = null;
                }
            }

            this.handleDrawer(false);
        },

        handleAction(type) {
            switch (type) {
                case 'PREV':
                    this.$router.push(this.prevLink);
                    break;
                case 'NEXT':
                    this.$router.push(this.nextLink);
                    break;
                case 'FINISH':
                    this.$confirm({
                        title: this.$t('lesson_page.finish'),
                        content: this.$t('lesson_page.confirm_finish_lesson'),
                        okText: this.$t('yes'),
                        cancelText: this.$t('cancel'),
                        onOk: () => {
                            this.$router.push({ path: `/course-detail-page/${this.courseObject.id}` });
                        },
                        onCancel() {},
                    });
                    break;
                default:
                    break;
            }
        },

        handleDrawer(value) {
            this.visible = value;
        },
    },
};
</script>

<style lang="scss">
.lesson__title {
    max-width: 50%;
    margin: 0 auto 20px;
    font-size: 32px;
    text-align: center;
}

.lesson__menu {
    position: sticky;
    position: -webkit-sticky;
    top: 100px;

    & h4 {
        margin-bottom: 10px;
        font-size: 16px;
    }

    & .list {
        max-height: 300px;
        overflow-y: auto;
        list-style: none;
    }

    & .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        color: #000;
        margin-right: 20px;
        padding: 7.5px 12px;
        background: #e4e6ed;
        border: 2px solid #e4e6ed;
        border-radius: 10px;
        transition: all 0.3s ease;

        & span {
            min-height: 21px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }

        & i {
            font-size: 20px;
            color: green;
        }
    }

    & .item + .item {
        margin-top: 8px;
    }

    & .item.active,
    & .item:hover {
        border-color: #007aff;
        color: #007aff;
    }
}

.lesson__menu-content,
.lesson__content {
    padding: 20px 0 20px 20px;
    background-color: #fff;
    border-radius: 16px;
}

.lesson__content {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 15px;
    padding: 40px 15px 25px;

    & .lesson__subTitle {
        margin-bottom: 16px;
        font-size: 20px;
        text-align: center;
    }

    & .lesson__text {
        margin-bottom: 40px;
        font-size: 16px;
    }
}

.lesson__btns {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    & button {
        width: 100px;
        padding: 6px;
        background-color: #fff;
        color: #656ef1;
        font-size: 16px;
        font-weight: bold;
        border: 2px solid #656ef1;
        border-radius: 6px;
        transition: all 0.3s ease;
    }

    & button + button {
        margin-left: 15px;
    }

    & button:not(:disabled):hover {
        background-color: #656ef1;
        color: #fff;
    }

    & button:disabled {
        opacity: 0.5;
    }
}

.box-mobile {
    display: none;
    margin-bottom: 20px;

    & h2 {
        width: calc(100% - 60px);
        margin: 0;
        font-size: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        text-align: left;
    }

    & button {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 4px;

        & i {
            color: #000 !important;
        }
    }
}

@media screen and (max-width: 1200px) {
    .box-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .lesson__menu {
        margin-top: 30px;

        & .list {
            max-height: calc(100vh - 100px);
        }

        & .item {
            margin-right: 10px;
        }
    }
}
</style>
