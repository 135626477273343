<template lang="">
    <div class="lesson-page">
        <ButtonBack :routeBack="routeBack" />
        <Lesson />
    </div>
</template>

<script>
import ButtonBack from '../../components/Web/ButtonBack';
import Lesson from '../../components/Web/Lesson';

export default {
    components: {
        ButtonBack,
        Lesson,
    },
    data() {
        return {
            routeBack: '/course-detail-page/' + (this.$route.query.course_id || ''),
        };
    },
};
</script>

<style scoped lang="scss">
.lesson-page {
    width: 100%;
    max-width: 1470px;
    padding: 0 15px;
    margin: 80px auto 80px;
}
</style>
