<template lang="">
    <div class="mb-8">
        <button class="btn-back" @click="handleBack">
            <v-icon class="fa-solid fa-angle-left"></v-icon>
            <span>{{ $t('go_back') }}</span>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        routeBack: { type: String },
    },
    methods: {
        handleBack() {
            if (this.routeBack) {
                this.$router.push(this.routeBack);
            } else {
                this.$router.go(-1);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.btn-back {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100px;
    padding: 5px;
    background-color: #656ef1;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    border: 2px solid #656ef1;
    border-radius: 6px;
    transition: all 0.3s ease;
    & i {
        margin-bottom: 2px;
        font-size: 18px;
        color: #fff;
    }
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 2px 6px rgba(101, 110, 241, 0.5);
    }
}
</style>
