import { HTTP } from '../config/http-common';
import { ROUTE_API } from '../constants/routes';
import { exportResults, convertParams } from '../utils/index';

export const findOne = async (id, params) =>
    exportResults(
        await HTTP.get(`${ROUTE_API.LESSON}/web/${id}`, {
            params: convertParams(params),
        }),
    );
